/* eslint-disable max-len */
import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsPartial,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';
import pageHeroData from '../../../data/pages/components.yml';
import styles from './styles/keyboardshortcuts.module.scss';

const IconKeyboardShortcuts = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconKeyboardShortcuts;
    return <Component {...props} />;
  },
});

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Keyboard Shortcut"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Keyboard Shortcut" />
      <V5Notice
        componentName="Keyboard Shortcut"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-keyboard-shortcut--docs"
      />
      <PageNavigation
        links={[
          'Style',
          'Functions',
          'Content',
          'Mobile',
          'Usage',
          'Accessibility',
          'Platform',
        ]}
      />
      <Section title="Style">
        <Paragraph>
          The keyboard shortcut and accompanying content should always appear as
          a table in-line, as a dialog or via a tooltip.
        </Paragraph>
        <ComponentPreview name="keyboardStyle">
          <KeyboardShortcut>shift</KeyboardShortcut>
          <span className="uni-margin--half--horizontal">+</span>
          <KeyboardShortcut>h</KeyboardShortcut>
        </ComponentPreview>
      </Section>

      <Section title="Functions">
        <Paragraph>
          If several shortcuts are available but real estate is limited, the
          shortcuts can be presented as a dialog triggered by the keyboard icon.
        </Paragraph>
        <ComponentPreview name="keyboardFunctions" layout="default">
          <div className={styles.kbdIconContainer}>
            <IconKeyboardShortcuts size="large" />
            <div className={'uni-bg--level1 ' + styles.kbdContainer}>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>a</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Backward 5 seconds</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>f</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Forward 5 seconds</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>s</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Back 1 frame</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>d</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Forward 1 frame</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>space</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Play/Pause</div>
              </div>
            </div>
          </div>
        </ComponentPreview>
      </Section>

      <Section title="Content">
        <Paragraph>
          Text options within the keyboard shortcut are limited. Case and
          punctuation depend entirely on what purpose the text serves.
        </Paragraph>
        <SectionSubhead>Keys</SectionSubhead>
        <Paragraph>
          All shortcuts should do their best to match what appears on the user’s
          keyboard.
        </Paragraph>
        <List type="unordered">
          <li>All single letters A-Z are uppercase.</li>
          <li>
            For non-letter keys such as <strong>enter</strong>,{' '}
            <strong>esc</strong> and <strong>shift</strong>, stick to lowercase.
          </li>
          <li>Use the arrow symbol as opposed to spelling things out.</li>
          <li>Same goes for numbers—numerals (1, 2, 3) are perfect.</li>
        </List>

        <SectionSubhead>Modifiers</SectionSubhead>
        <Paragraph>
          The only punctuation you should need is the <strong>+</strong> to
          indicate a <em>combination</em> of keys will activate the shortcut.
        </Paragraph>
        <div className="u-text-alignment--center uni-margin--two--vertical">
          <KeyboardShortcut>shift</KeyboardShortcut>
          <span className="uni-margin--half--horizontal">+</span>
          <KeyboardShortcut>h</KeyboardShortcut>
        </div>
        <Paragraph>
          For a sequence of keys where one must follow the other, spell out{' '}
          <strong>then</strong>. Stick to lowercase to match the non-letter
          keys.
        </Paragraph>
        <div className="u-text-alignment--center uni-margin--two--vertical">
          <KeyboardShortcut>f</KeyboardShortcut>
          <span className="uni-margin--half--horizontal">then</span>
          <KeyboardShortcut>w</KeyboardShortcut>
        </div>
        <Paragraph>
          If two different keys can execute the same action, or the shortcut
          itself may look different on the user’s keyboard, insert{' '}
          <strong>or</strong>.
        </Paragraph>
        <div className="u-text-alignment--center uni-margin--two--vertical">
          <KeyboardShortcut>alt</KeyboardShortcut>
          <span className="uni-margin--half--horizontal">or</span>
          <KeyboardShortcut>option</KeyboardShortcut>
        </div>

        <SectionSubhead>Results</SectionSubhead>
        <Paragraph>
          The action performed by a keyboard shortcut should not come off as a
          complete sentence. Use sentence case, but don’t give it closing
          punctuation.
        </Paragraph>
        <DontDo
          dontText="get too detailed with the result."
          doText="stick to the verb phrase."
          imgFilename="keyboardshortcut-result"
        />
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Never hide important actions or information under a keyboard shortcut
          on mobile devices. Instead, display this in the interface in an easy
          to see place. Consider using a{' '}
          <Link href="/components/notifications/note/design">note</Link>.
        </Paragraph>
      </Section>
      <Section title="Usage">
        <SectionSubhead>Unique Functions</SectionSubhead>
        <Paragraph>
          Never use one key for multiple functions. Avoid the direction to
          “hold” a key for the sake of performing an action different than that
          of a tap.
        </Paragraph>
        <Paragraph>
          Instead, give each action its own key or combination of keys. We never
          know how quickly or slowly a user may interact with their keyboard.
        </Paragraph>
        <DontDo
          dontText="include any modifier not related to sequence."
          doText="assign a unique function its own key."
          imgFilename="keyboardshortcut-function"
        />
        <SectionSubhead>Container</SectionSubhead>
        <Paragraph>
          Always build the shortcut in a table or grid with everything (shortcut
          and result) left-aligned. For recommended margins within that grid, be
          sure to check our{' '}
          <Link href="/guidelines/layout/space/design">space guidelines</Link>.
        </Paragraph>
        <DontDo
          dontText="center the shortcut, no matter how few there may be."
          doText="use a grid to make spacing easier."
          imgFilename="keyboardshortcut-container"
        />

        <SectionSubhead>Tooltip</SectionSubhead>
        <Paragraph>
          A keyboard shortcut displayed via a{' '}
          <Link href="/components/tooltip" isDesignCodeLink>
            tooltip
          </Link>{' '}
          should always appear in parentheses.
        </Paragraph>
        <Paragraph>
          Place it at the end of the tooltip itself, <em>following</em> the
          action they’d perform. (The action is required. A shortcut should
          never appear on its own!)
        </Paragraph>
        <DontDo
          dontText="provide a shortcut with zero context."
          doText="list the icon’s purpose first."
          imgFilename="keyboardshortcut-iconlabel"
        />
        <SectionSubhead>Organization</SectionSubhead>
        <Paragraph>
          Use logic to group related shortcuts. Anything related to playback
          should appear together, as should tags, highlights, page controls,
          etc.
        </Paragraph>
        <DontDo
          dontText="use a random system to organize shortcuts."
          doText="use headings to make large tables easier to navigate."
          imgFilename="keyboardshortcut-organization"
        />
      </Section>

      <Section title="Accessibility">
        <Paragraph>
          Targeted keys used in keyboard shortcuts may already have a different
          function assigned to it by our users. To make{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/character-key-shortcuts.html">
            keyboard shortcuts accessible to everyone
          </Link>
          , one of the following must be true:
        </Paragraph>
        <List>
          <li>There is a way to turn the shortcut off,</li>
          <li>
            There is a way to remap the shortcut to use non-printable characters
            such as <KeyboardShortcut>CTRL</KeyboardShortcut>, or
          </li>
          <li>
            The shortcut is only available when focused on an element, such as a
            button or form input.
          </li>
          <br />
        </List>
        <Paragraph>
          Uniform strives to follow the Web Content Accessibility Guidelines
          (WCAG). To learn more, check out these resources:
        </Paragraph>
        <List>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/character-key-shortcuts.html">
              WCAG Guideline for Character Key Shortcuts
            </Link>
          </li>
          <li>
            <Link href="https://www.a11yproject.com/posts/learning-common-keyboard-shortcuts-for-screen-readers/">
              Learning Common Keyboard shortcuts for Screen Readers - The A11y
              Project
            </Link>
          </li>
        </List>
        <AccessibilityAuditComponentResultsPartial componentName="Keyboard Shortcut" />
      </Section>

      <Section title="Platform">
        <PlatformTable
          platforms={{
            apple: (
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://developer.apple.com/design/human-interface-guidelines/macos/user-interaction/keyboard/">
                    HIG
                  </Link>{' '}
                  for macOS.
                </li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
